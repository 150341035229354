<template>
  <div>
    <div
      class="no-print"
      style="text-align:right;background: #fff; margin: 0 10px;padding:20px 30px 0 0"
    >
      <el-button size="text" style="float:left;margin-left:30px;color:#000" @click="$router.back()">返回</el-button>

      <el-button size="small" v-print="'#printPage'" type="primary" icon="el-icon-receiving">打印</el-button>
    </div>
    <div class="viewbox" style="background:#fff;margin:0px 10px;padding:20px 30px" id="printPage">
      <div class="baseDetail">
        <table cellspacing="0" cellpadding="0">
          <tr>
            <td class="pic" style="text-align:center" rowspan="12">
              <el-avatar
                shape="square"
                style="width:80%;height:100%"
                :src="form.staffBasicInfoDto &&form.staffBasicInfoDto.avatarUrl"
              ></el-avatar>
              <!-- <img :src="form.staffBasicInfoDto &&form.staffBasicInfoDto.avatarUrl"> -->
              <p>{{form.resumeBaseExportDto[0].name}}</p>
            </td>
            <td style="text-align:right;background: #f6f8fa;">部门</td>
            <td
              style="text-align:left"
            >{{form.resumeWorkExportDto[0] && form.resumeWorkExportDto[0].departmentName}}</td>
            <td style="text-align:right;background: #f6f8fa;">岗位</td>
            <td
              style="text-align:left"
            >{{form.resumeWorkExportDto[0].officeName}}</td>
            <td style="text-align:right;background: #f6f8fa;">聘用类型</td>
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto[0] && form.resumeBaseExportDto[0].postProperties}}</td>
          </tr>
          <tr>
            <td style="text-align:right;background: #f6f8fa;">投递类型</td>
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto[0] && form.resumeBaseExportDto[0].postType}}</td>
            <!-- <td style="text-align:right;background: #f6f8fa;">最近合同开始</td>!
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto && form.resumeBaseExportDto.maritalStatus}}</td> -->
            <td style="text-align:right;background: #f6f8fa;">转正日期</td>
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto && form.resumeBaseExportDto.turnPositiveTime}}</td>
            <td style="text-align:right;background: #f6f8fa;">民族</td>
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto[0] && form.resumeBaseExportDto[0].nationality}}</td>
          </tr>
          <!--<tr>
            <td style="text-align:right;background: #f6f8fa;">职位</td>
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto && form.resumeBaseExportDto.officeName}}</td>
            <td style="text-align:right;background: #f6f8fa;">最近合同结束</td>
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto && form.resumeBaseExportDto.contractEndTime}}</td>
          </tr>!-->
          <tr>
            <td style="text-align:right;background: #f6f8fa;">在职状态</td>
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto[0] && form.resumeBaseExportDto[0].typeName}}</td>
            <td style="text-align:right;background: #f6f8fa;">最高学历</td>
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto[0] && form.resumeBaseExportDto[0].highestEducation}}</td>
            <td style="text-align:right;background: #f6f8fa;">籍贯</td>
            <td
              style="text-align:left"
            ></td>
            <!-- {{form.resumeBaseExportDto[0] && form.resumeBaseExportDto[0].nationality}} -->
          </tr>
          <tr>
            <td style="text-align:right;background: #f6f8fa;">到岗期限</td>
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto[0] && form.resumeBaseExportDto[0].arriveTime}}</td>
            <td style="text-align:right;background: #f6f8fa;">毕业院校</td>
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto[0] && form.resumeBaseExportDto[0].graduatedSchool}}</td>
            <td style="text-align:right;background: #f6f8fa;">政治面貌</td>
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto[0] && form.resumeBaseExportDto[0].politicalStatus}}</td>
          </tr>
          <tr>
            <td style="text-align:right;background: #f6f8fa;">招聘渠道</td>
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto[0] && form.resumeBaseExportDto[0].sources}}</td>
            <td style="text-align:right;background: #f6f8fa;">专业</td>
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto[0] && form.resumeBaseExportDto[0].industry}}</td>
            <td style="text-align:right;background: #f6f8fa;">出生日期</td>
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto[0] && form.resumeBaseExportDto[0].birthday}}</td>
          </tr>
          <tr>
            <td style="text-align:right;background: #f6f8fa;">入职时间</td>
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto && form.resumeBaseExportDto.entryTime}}</td>
            <td style="text-align:right;background: #f6f8fa;">毕业年月</td>
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto && form.resumeBaseExportDto.graduatedTime}}</td>
            <td style="text-align:right;background: #f6f8fa;">婚姻状况</td>
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto[0] && form.resumeBaseExportDto[0].maritalStatus}}</td>
          </tr>
          <tr>
            <td style="text-align:right;background: #f6f8fa;">家庭住址</td>
            <td
              colspan="3"
              style="text-align:left"
            >{{form.resumeBaseExportDto[0] && form.resumeBaseExportDto[0].homeAddress}}</td>
            <td style="text-align:right;background: #f6f8fa;">家庭电话</td>
            <td
              style="text-align:left"
            >{{form.staffBasicInfoDto && form.staffBasicInfoDto.emergencyContactPhone}}</td>
          </tr>
          <tr>
            <td style="text-align:right;background: #f6f8fa;">邮箱</td>
            <td
              style="text-align:left"
              colspan="3"
            >{{form.resumeBaseExportDto[0].mailbox}}</td>
            <td style="text-align:right;background: #f6f8fa;">手机</td>
            <td
              style="text-align:left"
            >{{form.resumeBaseExportDto[0].phoneNumber}}</td>
          </tr>
          <tr>
            <td style="text-align:right;background: #f6f8fa;">职责</td>
            <td
              style="line-height:20px;text-align:justify;padding:10px;"
              colspan="5"
            >{{form.resumeBaseExportDto && form.resumeBaseExportDto.duty}}</td>
          </tr>
          <tr>
            <td style="text-align:right;background: #f6f8fa;">主要业务</td>
            <td
              style="line-height:20px;text-align:justify;padding:10px;"
              colspan="5"
            >{{form.resumeBaseExportDto && form.resumeBaseExportDto.business}}</td>
          </tr>
           <tr>
            <td style="text-align:right;background: #f6f8fa;">自我评价</td>
            <td
              style="line-height:20px;text-align:justify;padding:10px;"
              colspan="5"
            >{{form.resumeBaseExportDto[0] && form.resumeBaseExportDto[0].selfAssessment}}</td>
          </tr>
        </table>
      </div>
      <div class="details">
        <div>
          <div class="detailTitle">
            <div class="titleIcon"></div>
            <div>教育经历</div>
          </div>
          <table cellspacing="0" cellpadding="0">
            <thead style="background: #f6f8fa;">
              <td style="text-align:center">毕业年月</td>
              <td style="text-align:center">学历</td>
              <td style="text-align:center">毕业院校</td>
              <td style="text-align:center">专业</td>
            </thead>
            <tr v-for="(item,index) in form.resumeEducationExportDto" :key="index">
              <td>{{item.graduationTime && item.graduationTime.slice(0,10)}}</td>
              <td>{{item.education}}</td>
              <td>{{item.graduatedSchool}}</td>
              <td>{{item.profession}}</td>
            </tr>
          </table>
        </div>
        <div>
          <div class="detailTitle">
            <div class="titleIcon"></div>培训内容
          </div>
          <table cellspacing="0" cellpadding="0">
            <thead style="background: #f6f8fa;">
              <td style="text-align:center">开始时间</td>
              <td style="text-align:center">结束时间</td>
              <td style="text-align:center">培训课程</td>
              <td style="text-align:center">考核结果</td>
            </thead>
            <tr v-for="(item,index) in form.resumeTrainExportDto" :key="index">
              <td>{{item.traStartTime && item.traStartTime.slice(0,10)}}</td>
              <td>{{item.traEndTime && item.traEndTime.slice(0,10)}}</td>
              <td>{{item.traCourse}}</td>
              <td>{{item.exaResults}}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="details">
         <div>
          <div class="detailTitle">
            <div class="titleIcon"></div>证书
          </div>
          <table cellspacing="0" cellpadding="0">
            <thead style="background: #f6f8fa;">
              <td style="text-align:center">资格证</td>
              <td style="text-align:center">等级</td>
              <td style="text-align:center">分数</td>
              <td style="text-align:center">颁发时间</td>
              <!-- <td>结束时间</td> -->
            </thead>
            <tr v-for="(item,index) in form.resumeCertificateExportDto" :key="index">
              <td>{{item.cerName}}</td>
              <td>{{item.grade}}</td>
              <td>{{item.score||""}}</td>
              <td>{{item.startTime}}</td>
              <!-- <td>{{item.endTime}}</td> -->
            </tr>
          </table>
        </div>
      </div>
      <div class="details">
        <div style="width:100%;margin-right:0">
          <div class="detailTitle">
            <div class="titleIcon"></div>工作经历
          </div>
          <table cellspacing="0" cellpadding="0">
            <thead style="background: #f6f8fa;">
              <td style="text-align:center">开始时间</td>
              <td style="text-align:center">结束时间</td>
              <td style="text-align:center">公司名称</td>
              <td style="text-align:center">部门</td>
              <td style="text-align:center">职位</td>
            </thead>
            <tr v-for="(item,index) in form.resumeWorkExportDto" :key="index">
              <td>{{item.workStartTime && item.workStartTime.slice(0,10)}}</td>
              <td>{{item.workEndTime && item.workEndTime.slice(0,10)}}</td>
              <td style="text-align:left">{{item.company}}</td>
              <td>{{item.departmentName}}</td>
              <td>{{item.officeName}}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="details">
        <div style="width:100%;margin-right:0">
          <div class="detailTitle">
            <div class="titleIcon"></div>项目经验
          </div>
          <table cellspacing="0" cellpadding="0">
            <thead style="background: #f6f8fa;">
              <td style="text-align:center" width="100">项目名称</td>
              <td style="text-align:center" width="200">开始时间</td>
              <td style="text-align:center" width="200">结束时间</td>
              <td style="text-align:center" width="200">项目介绍</td>
              <td style="text-align:center" width="200">职责描述</td>
              <td style="text-align:center" width="200">主要业绩</td>
            </thead>
            <tr v-for="(item,index) in form.resumeProjectExportDto" :key="index">
              <td>{{item.proName}}</td>
              <td>{{item.proStartTime && item.proStartTime.slice(0,10)}}</td>
              <td>{{item.proEndTime && item.proEndTime.slice(0,10)}}</td>
              <td>{{item.proIntrod}}</td>
              <td>{{item.proDuties}}</td>
              <td>{{item.proPerfor}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {printresumelist} from '../../api/recruitment_allocation';
export default {
  data() {
    return {
      form: {
        resumeBaseExportDto: {
          name: '张三'
        }
      }
    };
  },
  mounted() {
    this.getRemuseInfo();
  },
  methods: {
    getRemuseInfo() {
      printresumelist({ ids: [this.$route.params.id] }).then((res) => {
        this.form = res;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.baseDetail {
  display: flex;
  margin-top: 10px;
  table {
    width: 100%;
    min-width: 500px;
    border: 1px solid #b0bacb;
    :first-child {
      td:nth-child(even) {
        text-align: right;
        background: #f6f8fa;
        padding: 10px;
      }
      td:nth-child(odd) {
        background: #fff;
        text-align: left;
        padding: 10px;
      }
    }
    tr {
      line-height: 2.3;
      .pic {
        background: #ffffff !important;
        padding: 0px !important;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
        }
        p {
          text-align: center;
          margin-bottom: 0;
          margin-top: 0;
        }
      }
    }
    td {
      border: 1px solid #b0bacb;
      vertical-align: middle;
    }
    td:nth-child(odd) {
      text-align: right;
      background: #f6f8fa;
      width: 14%;
      padding-right: 10px;
    }
    td:nth-child(even) {
      text-align: left;
      padding-left: 10px;
    }
  }
}
.details {
  display: flex;
  margin-top: 10px;
  .detailTitle {
    display: flex;
    // justify-conten:center;
    height: 30px;
    font-size: 10px;
    font-size: 18px;
  }
  div {
    width: calc(100% / 2);
  }
  div:first-child {
    margin-right: 20px;
  }
  table {
    width: 100%;
    height: 150px;
    border: 1px solid #b0bacb;
    thead {
      td {
        height: 50px;
      }
    }
    td {
      border: 1px solid #b0bacb;
      word-break: break-all;
      line-height: 20px;
      text-align: center;
      padding: 10px;
      vertical-align: middle;
    }
  }
}
.titleIcon {
  display: inline-block;
  width: 8px !important;
  height: 20px;
  background: #498df0;
  border-radius: 5px;
  vertical-align: bottom;
  margin-right: 5px !important;
}
</style>
