/*
 * @Author: 卢均锐morye
 * @Date: 2020-04-11 14:32:55
 * @LastEditTime: 2020-04-18 16:08:35
 */
import {
    // get,
    post
} from "./api";
//招聘岗位状态数量
export const recruitStationNum = params => post('/v1/recruit/station/num', { request: params });
//招聘岗位列表
export const recruitStationList = params => post('/v1/recruit/station/list', { request: params });
//招聘岗位保存
export const recruitStationSave = params => post('/v1/recruit/station/save', { request: params });
//招聘计划数据
export const professionJobNumber = params => post('/v1/profession/job/number', { request: params });
//招聘岗位详情
export const recruitStationDetail = params => post('/v1/recruit/station/detail', { request: params });
//员工列表
export const selectPrincipallist = params => post('/v1/common/selectPrincipal/list', { request: params });
//面试轮次列表查询
export const configRoundList = params => post('/v1/recruit/config/round/list', { request: params });
//面试轮次保存
export const configRoundSave = params => post('/v1/recruit/config/round/save', { request: params });
//面试轮次改变
export const configRoundStatusChange = params => post('/v1/recruit/config/round/status/change', { request: params });
//面试轮次删除
export const configRoundDelete = params => post('/v1/recruit/config/round/delete', { request: params });
//招聘岗位删除
export const recruitStationDelete = params => post('/v1/recruit/station/delete', { request: params });
//招聘岗位关闭
export const recruitStationClose = params => post('/v1/recruit/station/close', { request: params });
//简历列表
export const resumeList = params => post('/v1/recruit/resume/list', { request: params });
//备选简历
export const alternativeResumeList = params => post('/v1/recruit/resume/list/2', { request: params });
//简历备选
export const resumeAlternative = params => post('/v1/recruit/resume/alternative', { request: params });
//简历详情
export const resumeDetail = params => post('/v1/recruit/resume/detail', { request: params });
//简历保存
export const resumeSave = params => post('/v1/recruit/resume/save', { request: params });
//简历工作经验保存
export const resumeWorkExperienceSave = params => post('/v1/recruit/resume/work/experience/save', { request: params });
//简历项目经验保存
export const resumeProjectExperienceSave = params => post('/v1/recruit/resume/project/experience/save', { request: params });
//简历教育经历保存
export const resumeEducationSave = params => post('/v1/recruit/resume/education/save', { request: params });
//简历培训经历保存
export const resumeTrainingtSave = params => post('v1/recruit/resume/training/save', { request: params });
//简历证书记录保存
export const resumeCertificateSave = params => post('/v1/recruit/resume/certificate/save', { request: params });
//简历状态变更
export const resumeStatusChange = params => post('/v1/recruit/resume/status/change', { request: params });
//面试地址数据
export const configAddressList = params => post('/v1/recruit/config/address/list', { request: params });
//新增面试地址
export const configAddressSave = params => post('/v1/recruit/config/address/save', { request: params });
//安排面试详情
export const interviewDetail = params => post('/v1/recruit/resume/interview/detail', { request: params });
//安排面试保存
export const interviewSave = params => post('/v1/recruit/resume/interview/save', { request: params });
//标签列表
export const tagList = params => post('/v1/tag/list', { request: params });
//面试模块数量
export const interviewNum = params => post('/v1/recruit/interview/manage/num', { request: params });
//应聘登记列表
export const resumeApply = params => post('/v1/recruit/resume/apply/list', { request: params });
//应聘登记保存
export const resumeApplySave = params => post('/v1/recruit/resume/apply/save', { request: params });
//应聘登记详情
export const resumeApplyInfo = params => post('/v1/recruit/resume/apply/detail', { request: params });
//当日待面试列表
export const dayInterview = params => post('/v1/recruit/interview/manage/day', { request: params });
//待面试列表
export const waitInterview = params => post('/v1/recruit/interview/manage/wait', { request: params });
//面试中列表
export const ingInterview = params => post('/v1/recruit/interview/manage/ing', { request: params });
//已面试列表
export const passInterview = params => post('/v1/recruit/interview/manage/pass', { request: params });
//变更面试官
export const changeStaff = params => post('/v1/recruit/interview/change/staff', { request: params });
//面试记录
export const interviewRecords = params => post('/v1/recruit/interview/records', { request: params });
//员工-招聘数量
export const interviewApprovalNum = params => post('/v1/recruit/interview/approval/num', { request: params });
//员工-招聘调配列表
export const interviewApprovalList = params => post('/v1/recruit/interview/approval/list', { request: params });
//员工-面试
export const interviewApproval = params => post('/v1/recruit/interview/approval', { request: params });
//员工-新增评价
export const interviewEvaluate = params => post('/v1/recruit/interview/evaluate', { request: params });
//员工-人才评估保存
export const interviewReportSave = params => post('/v1/recruit/interview/report/save', { request: params });
//人才评估查看
export const talentSelectUserAssessReport = params => post('/v1/recruit/interview/report/detail', { request: params });
//录取
export const employment = params => post('/v1/recruit/interview/employment', { request: params });
//邮件模板管理查询
export const emailList = params => post('/v1/recruit/config/email/list', { request: params });
//待入职列表 2020-03-05 start
export const noEntryList = params => post('/v1/recruit/no/entry/list', { request: params });
//已入职列表
export const recruitEntryList = params => post('/v1/recruit/entry/list', { request: params });
//已过期列表
export const recruitOverdueList = params => post('/v1/recruit/overdue/list', { request: params });
//已拒绝列表
export const recruitRejectList = params => post('/v1/recruit/reject/list', { request: params });
//人才移库操作
export const recruitTalentMove = params => post('/v1/recruit/talent/move', { request: params });
//入职
export const recruitEntry = params => post('/v1/recruit/entry', { request: params });
//申请入职
export const recruitEntryApproval = params => post('/v1/recruit/entry/approval', { request: params });
//入职审批列表
export const recruitEntryApprovalList = params => post('/v1/recruit/entry/approval/list', { request: params });
//审批详情
export const recruitEntryApprovalDetail = params => post('/v1/recruit/entry/approval/detail', { request: params });
//再入职审批详情
export const recruitEntryAgainDetail = params => post('/v1/recruit/entry/approval/detail/by/resume', { request: params });
//员工-再次入职审批
export const recruitAnewEntryApproval = params => post('/v1/recruit/anew/entry/approval', { request: params });
//员工-在入职审批列表 2020-03-05 end
export const recruitAnewEntryApprovalList = params => post('/v1/recruit/anew/entry/approval/list', { request: params });
//简历详情 2020-03-06 start
export const recruitResumeDetail = params => post('/v1/recruit/resume/detail', { request: params });
//morye 2020-04-13 start
//招聘概览图表
export const probability = params => post('/v1/recruit/statistics/hiring/probability', { request: params });
//招聘漏斗-上方详情
export const statisticsHiringFunnel = params => post('/v1/recruit/statistics/hiring/funnel', { request: params });
//招聘漏斗-下方按岗位
export const statisticsJob = params => post('/v1/recruit/statistics/job', { request: params });
//招聘全数据-接收简历
export const statisticsReceiveTrend = params => post('/v1/recruit/statistics/receive/trend', { request: params });
//招聘全数据-渠道
export const statisticsReceiveSourcesPiechart = params => post('/v1/recruit/statistics/sources/piechart', { request: params });
//招聘全数据-岗位
export const statisticsReceiveJobPiechart = params => post('/v1/recruit/statistics/job/piechart', { request: params });
//招聘全数据-offer
export const statisticsReceiveOfferPiechart = params => post('/v1/recruit/statistics/offer/piechart', { request: params });
//人才库-已淘汰
export const getListObsolete = params => post('/v1/recruit/resume/list/obsolete', { request: params });
//人才库-人才储备
export const getListReserve = params => post('/v1/recruit/resume/list/reserve', { request: params });
//人才库-黑名单
export const getListBlack = params => post('/v1/recruit/resume/list/black', { request: params });
//人才库-删除
export const getListDelete = params => post('/v1/recruit/resume/list/delete', { request: params });
//人才库-已入职
export const getListHired = params => post('/v1/recruit/resume/list/hired', { request: params });
//人才库-离职
export const getListDimission = params => post('/v1/recruit/resume/list/dimission', { request: params });
//获取移库类型及原因
export const classifyList = params => post('/v1/tag/classify/list', { request: params });
//简历数量
export const listNum = params => post('/v1/recruit/resume/list/num', { request: params });
//简历列表打印
export const printresumelist = params => post('/v1/recruit/resume/list/print', { request: params });